import React, { useEffect, useState, useReducer, useRef, useContext, } from "react";
import { useForm } from "react-hook-form";
import "../../assets/Questionnaire21/scss/modules/custom.scss";
import "../../assets/Questionnaire21/scss/modules/questionnaire.scss";
import GetVisitorsParams from "../../Utility/GetVisitorsParams";
import logowhite from "../../assets/Questionnaire21/img/logo-white.png";
import Loader from "../Includes/Layouts/QuestionnaireGN_4/Loader";
import StillActive from "../Includes/Layouts/QuestionnaireGN_4/StillActive";
import EmploymentStatus from "../Includes/Layouts/QuestionnaireGN_4/EmploymentStatus";
// import Dependents from "../Includes/Layouts/QuestionnaireGN_4/Dependents";
import { questinnare1, questinnaire36Vanquis, questinnaire76Vanquis, answerArray, supportDocTypeArray, wereUalreadyPaying } from "../../Constants/Questions";
import IncomeAndExp from "../Includes/Layouts/QuestionnaireGN_4/IncomeAndExp";
import Emailconfirmation from "../Includes/Layouts/QuestionnaireGN_4/Emailconfirmation";
import { useSkippedLeads } from "../../Hooks/useSkippedLeads";
import { useQuestionnaire } from "../../Hooks/useQuestionnaire";
import { userMilestoneLive } from "../../Hooks/userMilestoneLive";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { FormData } from "../../Utility/FormData";
import { userInfo } from "../../Hooks/userInfo";
import { useHistory } from "react-router-dom";
import {
  MonthNumberArray
}
  from "../../Constants/Constants";
import Footer from "../Includes/Layouts/QuestionnaireGN_4/Footer";
import GtmDataLayer from "../../Utility/GtmDataLayer";
import PrivatePlate from "../Includes/Layouts/QuestionnaireGN_4/PrivatePlate";
import PrivatePlateNum from "../Includes/Layouts/QuestionnaireGN_4/PrivatePlateNum";
import StillHaveVehicle from "../Includes/Layouts/QuestionnaireGN_4/StillHaveVehicle";
import { useUpdateMilestone } from "../../Hooks/useUpdateMilestone";

import IncomeAndExpV2 from "../Includes/Layouts/QuestionnaireGN_4/IncomeAndExpV2";
import DidReceiveIncome from "../Includes/Layouts/QuestionnaireGN_4/DidReceiveIncome";
import CardMonthlyIncome from "../Includes/Layouts/QuestionnaireGN_4/CardMonthlyIncome";
import WereAlreadyPaying from "../Includes/Layouts/QuestionnaireGN_4/WereAlreadyPaying";
import DependentsV2 from "../Includes/Layouts/QuestionnaireGN_4/DependentsV2";
import IncomeAndExpV3 from "../Includes/Layouts/QuestionnaireGN_4/IncomeAndExpV3";
import SortCode from "../Includes/Layouts/QuestionnaireGN_4/SortCode";
import AccountNumber from "../Includes/Layouts/QuestionnaireGN_4/AccountNumber";
import { Api } from "../../api/Api";


let OBStatementValue;
let showSlide1Condition;
const initialState = {
  showSlide_DidReceiveIncome: "showSlide1Condition",
  showSlide_CardMonthlyIncome: "hide",
  showSlide_DependentsV2: "hide",
  showSlide_WereAlreadyPaying: "hide",
  showSlide_IncomeAndExpV2: "hide",
  showSlide_SortCode: "hide",
  showSlide_AccountNumber: "hide",
  showSlide2: "showSlide1Condition",
  showSlide2a: "hide",
  showSlide3: "hide",
  showSlide3a: "hide",
  showSlide4: "hide",
  showSlide4a: "hide",
  showSlide5: "hide",
  showSlide5a: "hide",
  showSlide6: "hide",
  showSlide6a: "hide",
  showSlide7: "hide",
  showSlide7a: "hide",
  showSlide8: "hide",
  showSlide8a: "hide",
  showSlide9: "hide",
  showSlide9a: "hide",
  prev: "",
  q77prev: "",
  IandE:"",
  consentStatus: false
};


const FormReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_SLIDES": {
      const OBStatementValue = action.payload.OBStatementValue;
      const consentStatus = action.payload.consentStatus;
      showSlide1Condition = (OBStatementValue !== "1");

      if(OBStatementValue == 1 && consentStatus == false) {
        return {
          ...state,
          showSlide_SortCode: "show",
          showSlide2: "hide",
          showSlide_DidReceiveIncome: "hide",
          consentStatus: consentStatus
        };
      }

      return {
        ...state,
        showSlide_DidReceiveIncome: showSlide1Condition ? "show" : "hide",
        showSlide2: showSlide1Condition ? "hide" : "show",
        consentStatus: consentStatus
      };

    }
    case "showSlide": {

      if (action.payload.clickedSlide.slide == "didReceiveIncome" && action.payload.clickedSlide.slide_value == "226") {
        /* GtmDataLayer({
          question: "income and expenditure",
        }); */
        return {
          ...state,
          showSlide_CardMonthlyIncome: "show",
        };
      } else if (action.payload.clickedSlide.slide == "didReceiveIncome" && action.payload.clickedSlide.slide_value == "227") {
        /* GtmDataLayer({
          question: "income and expenditure",
        }); */
        return {
          ...state,
          showSlide_DependentsV2: "show",
        };
      } else if (action.payload.clickedSlide.slide == "cardMonthlyIncome") {
        GtmDataLayer({
          question: "monthly income",
        });
        return {
          ...state,
          showSlide_DependentsV2: "show",
        };
      } else if (action.payload.clickedSlide.slide == "dependents") {
        GtmDataLayer({
          question: "dependents",
        });
        return {
          ...state,
          showSlide_WereAlreadyPaying: "show",
        };
      } else if (action.payload.clickedSlide.slide == "wereAlreadyPaying") {
        GtmDataLayer({
          question: "paying monthly",
        });
        if(!state.consentStatus) {
          return {
            ...state,
            showSlide_SortCode: "show",
          };
        } else {
          return {
            ...state,
            showSlide2: "show",
          };
        }
      } else if (action.payload.clickedSlide.slide == "sortCode") {
        GtmDataLayer({
          question: "sort code",
        });
        return {
          ...state,
          showSlide_AccountNumber: "show",
        };
      } else if (action.payload.clickedSlide.slide == "accountNumber") {
        GtmDataLayer({
          question: "accout number",
        });
        return {
          ...state,
          showSlide2: "show",
        };
      }
      /* else if (action.payload.clickedSlide.slide == "incmAndMonth") {
        GtmDataLayer({
          question: "income and expenditure",
        });
        return {
          ...state,
          showSlide_WereAlreadyPaying: "hide",
          showSlide2: "show",
        };
      }  */
      else if (action.payload.clickedSlide.slide == "emailConfirmation" && (action.payload.clickedSlide.slide_value == "162" || action.payload.clickedSlide.slide_value == "195")) {
        GtmDataLayer({
          question: "reg. email",
        });
        return {
          ...state,
          showSlide3: "show",
          showSlide3a: "show",
        };
      } else if (action.payload.clickedSlide.slide == "btn_qest_48") {
        GtmDataLayer({
          question: "reg. email",
        });
        return {
          ...state,
          showSlide3: "show",
          showSlide3a: "show",
        };
      }else if (action.payload.clickedSlide.slide == "confirmPlate" && action.payload.clickedSlide.slide_value == "121") {
        GtmDataLayer({
          question: "private plate",
        });
        return {
          ...state,
          // showSlide14: "hide",
          showSlide14a: "hide",
          showSlide4: "show",
          showSlide4a: "show",
        };
      }else if (action.payload.clickedSlide.slide == "confirmPlate" && (action.payload.clickedSlide.slide_value == "122" || action.payload.clickedSlide.slide_value == "195")) {
        GtmDataLayer({
          question: "private plate",
        });
        return {
          ...state,
          // showSlide14: "hide",
          showSlide14a: "hide",
          showSlide5: "show",
          showSlide5a: "show",
        };
      } else if (action.payload.clickedSlide.slide == "btn_qest_44") {
        GtmDataLayer({
          question: "reg. private plate",
        });
        return {
          ...state,
          showSlide5: "show",
          showSlide5a: "show",
        };
      }
      else if (action.payload.clickedSlide.slide == "stillActBtn") {
        GtmDataLayer({
          question: "finance agreement still active",
        });
        return {
          ...state,
          // showSlide4: "hide",
          showSlide4a: "hide",
          showSlide6: "show",
          showSlide6a: "show",
        };
      } else if (action.payload.clickedSlide.slide == "stillHave") {
        GtmDataLayer({
          question: "still have vehicle",
        });
        return {
          ...state,
          // showSlide4: "hide",
          showSlide4a: "hide",
          showSlide7: "show",
          showSlide7a: "show",
        };
      } else if (action.payload.clickedSlide.slide == "employmentsts") {
        GtmDataLayer({
          question: "employment status",
        });
        return {
          ...state,
          // showSlide8: "hide",
          showSlide8: "show",
          showSlide8a: "show",
        };
      }
      else {
        return {
          ...state,
        };
      }
    }


    case "previousSlides": {
 
      if (action.payload.previousSlide.slide == "goToCheckList") {
        return {
          ...state,
          showSlide_DidReceiveIncome: "hide",
        };
      } else if (action.payload.previousSlide.slide == "didReceiveIncome") {
        return {
          ...state,
          showSlide_CardMonthlyIncome: "hide",
          showSlide_DependentsV2: "hide",
          showSlide_DidReceiveIncome: "show",
        };
      } else if (action.payload.previousSlide.slide == "cardMonthlyIncome") {
        return {
          ...state,
          showSlide_DependentsV2: "hide",
          showSlide_CardMonthlyIncome: "show",
        };
      } else if (action.payload.previousSlide.slide == "dependents") {
        return {
          ...state,
          showSlide_WereAlreadyPaying: "hide",
          showSlide_DependentsV2: "show",
        };
      } 
      else if (action.payload.previousSlide.slide == "wereAlreadyPaying") {
        return {
          ...state,
          showSlide_SortCode: "hide",
          showSlide2: "hide",
          showSlide_WereAlreadyPaying: "show",
        };
      }
      else if (action.payload.previousSlide.slide == "sortCode") {
        return {
          ...state,
          showSlide_AccountNumber: "hide",
          showSlide_SortCode: "show",
        };
      } 
      else if (action.payload.previousSlide.slide == "accountNumber") {
        return {
          ...state,
          showSlide_IncomeAndExpV2: "hide",
          showSlide2: "hide",
          showSlide_AccountNumber: "show",
        };
      } 
      /* else if (action.payload.previousSlide.slide == "incmAndMonth") {
        return {
          ...state,
          showSlide2: "hide",
          showSlide_IncomeAndExpV2: "show",
        };
      } */
      else if (action.payload.previousSlide.slide == "emailConfirmation") {
        return {
          ...state,
          showSlide3a: "hide",
          showSlide3: "hide",
          showSlide2: "show",
          showSlide2a: "show",
        };
      }else if (action.payload.previousSlide.slide == "btn_qest_44" && (state.prev == "122" || state.prev == "195")) {
        return {
          ...state,
          showSlide5a: "hide",
          showSlide5: "hide",
          showSlide3: "show",
          showSlide3a: "show",
        };
      }else if (action.payload.previousSlide.slide == "confirmPlate") {
        return {
          ...state,
          showSlide4a: "hide",
          showSlide4: "hide",
          showSlide3: "show",
          showSlide3a: "show",
        };
      }else if (action.payload.previousSlide.slide == "btn_qest_44" && state.prev == "121") {
        return {
          ...state,
          showSlide5a: "hide",
          showSlide5: "hide",
          showSlide4: "show",
          showSlide4a: "show",
        };
      }else if (action.payload.previousSlide.slide == "stillActBtn") {
        return {
          ...state,
          showSlide6a: "hide",
          showSlide6: "hide",
          showSlide5: "show",
          showSlide5a: "show",
        };
      }else if (action.payload.previousSlide.slide == "stillHave") {
        return {
          ...state,
          showSlide7a: "hide",
          showSlide7: "hide",
          showSlide6: "show",
          showSlide6a: "show",
        };
      }
      else if (action.payload.previousSlide.slide == "employmentsts") {
        return {
          ...state,
          showSlide8a: "hide",
          showSlide8: "hide",
          showSlide7: "show",
          showSlide7a: "show",
        };
      }

    }
    default:
      return state;
  }
};
const QuestionnaireGN_4 = () => {

  const splitForm = useRef(null);
  const scrollRef = useRef([]);
  const history = useHistory();
  const [showLoad, setShowLoad] = useState("");
  const [clickedSlide, setClickedSlide] = useState([]);
  const [state, dispatch] = useReducer(FormReducer, initialState);
  const [optionSelected] = useState([]);
  const [SelectedOptions] = useState([]);
  const [completedQuestArr, setCompletedQuestArr] = useState([]);
  const [isRegisteredEmail, setIsRegisteredEmail] = useState('show');
  const [isNewEmailVisible, setIsNewEmailVisible] = useState('hide');
  const [agNoShow, setAgNoShow] = useState("hide");
  const { skippedLeads } = useSkippedLeads();
  const { isLoading, SaveQuestionnaire } = useQuestionnaire();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const query_string = localStorage.getItem("querystring");
  const formdata = JSON.parse(localStorage.getItem("formData"));
  const { setFormData } = FormData();
  const [splitName, setSplitName] = useState();
  const [splitNameForSkip, setSplitNameForSkip] = useState();
  const [docType, setDocType] = useState("");
  const [showUserName, setUserName] = useState("");
  const { getUserInfo } = userInfo();
  const [supportDocType, setSupportDocType] = useState("");
  const [userEmail, setUserEmail] = useState();
  const [supportDoc, setSupportDoc] = useState("");
  const [questionCounter, setQuestionCounter] = useState(0);
  // let [totalQuestArr, setTotalQuestArr] = useState([61,56,55,54,53,3,2,72,71,70,26,31,36,,76,9,30,62,42,46]);
  let [totalQuestArr, setTotalQuestArr] = useState([61, 56, 55, 54, 53, 3, 2, 72, 71, 70, 26, 31, 36, 76, 9, 30, 62, 46]);
  const [previousSlide, setPreviousSlide] = useState([]);
  const [previous, setPrevious] = useState("");
  const [percentage, SetPercentage] = useState(0);
  const [stillAct, setStillAct] = useState("");
  const [bankStmt,setBankStmt] = useState("");
  const {  updateMilestone } = userMilestoneLive();
  const [obValue, setObValue] = useState("");
  const [obValue2, setObValue2] = useState("");
  const {updateMilestone : updateMilestoneNew} = useUpdateMilestone();


  const [quest77Previous, setquest77Previous] = useState("");
  const [checklistOptions, setChecklistOptions] = useState([]);
  const [inputListValues, setInputListValues] = useState([]);
  const [sortCodeNumber, setSortCodeNumber] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [consentStatus, setConsentStatus] = useState(false);

  const [lenderName, setLenderName] = useState(localStorage.getItem('bud_lender'));





  const { register, errors, trigger, setError, clearErrors, getValues } =
    useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });

  const getUuid = () => {
    const visitorData = visitorParameters.visitor_parameters;
    if (visitorData != "") {
      var uuid = visitorData.uuid;
    } else {
      const query = new URLSearchParams(window.location.search);
      const query_uuid = query.get("uuid");
      const local_storage_uuid = localStorage.getItem("uuid");
      var uuid = query_uuid ? query_uuid : local_storage_uuid;
    }
    return uuid;
  };
  OBStatementValue = localStorage.getItem("OBStatement");
  const goBack = () => {
    window.location.href = "/CheckListGN_4?uuid=" + uuid;
    // history.push("/CheckListGN_4?uuid=" + uuid);
  }
  const uuid = getUuid();
    
  useEffect(async () => {
    let consentStatus = false;
    const uuid = await getUuid();
    const response = await Api.get(
      "/api/v1/get-customer-consent-status?uuid=" + uuid,
      {}
    );

    if (response.data.status == "Success") {
      consentStatus = response.data.consentStatus;
      if(response.data.data?.account_number) {
        localStorage.setItem('account_number', response.data.data.account_number);
        localStorage.setItem('sort_code', response.data.data.sort_code);
      }
    }
    setConsentStatus(consentStatus);

    // Fetch values from localStorage
    OBStatementValue = localStorage.getItem("OBStatement");
    setBankStmt(OBStatementValue);
    if(OBStatementValue == "1") {
        setObValue("hide")
        setObValue2("show")
    } else {
      setObValue("show")
      setObValue2("hide")
    }
    // Dispatch action to update slides based on localStorage values
    dispatch({
      type: "UPDATE_SLIDES",
      payload: { 
        "OBStatementValue" : OBStatementValue, 
        "consentStatus" : consentStatus 
      },
    });
  }, []);

  useEffect(() => {
    if (formdata === undefined || formdata === null || formdata == "") {
      (async () => {
        const name = localStorage.getItem("UcFname");
        const response = await getUserInfo(uuid);
        const set_formdata = setFormData(response);
        let dataLength = response.data.response.length;
        if (dataLength > 0) {
          const pageName = response.data.response[0].page_name
            ? response.data.response[0].page_name
            : " ";
          setSplitName(pageName);
          if (localStorage.getItem("split_name") != undefined && localStorage.getItem("split_name") != undefined) {
            setSplitNameForSkip(localStorage.getItem("split_name"));
          } else {
            setSplitNameForSkip(pageName);
          }
          const userName = response.data.response[0].first_name
            ? response.data.response[0].first_name +
            " " +
            response.data.response[0].last_name
            : " ";
          var userDobN = response.data.response[0].user_dob.split('/');
          var DobDayF = userDobN[0];
          var DobMonthF = userDobN[1];
          var DobYearF = userDobN[2];
          setSupportDocType(response.data.response[0]?.questionnaire[42]?.answerId);
          // setLenderName(response.data.response[0]?.questionnaire[64]['answerId']);
          setLenderName( response?.data?.response[0]?.questionnaire?.ob_lender);
          setUserName(name);
          setUserEmail(response.data.response[0].email);
          const timer = setTimeout(() => {
            setShowLoad("hide");
          }, 3000);
        }
      })();
    } else {
      const name = localStorage.getItem("UcFname");
      const pageName = formdata.page_name;
      setSplitName(pageName);
      if (localStorage.getItem("split_name") != undefined && localStorage.getItem("split_name") != undefined) {
        setSplitNameForSkip(localStorage.getItem("split_name"));
      } else {
        setSplitNameForSkip(pageName);
      }
      const userName = formdata.txtFName + " " + formdata.txtLName;
      setUserName(name);
      const dobMonth = formdata.DobMonth ? MonthNumberArray[formdata.DobMonth] : '';

      const timer = setTimeout(() => {
        setShowLoad("hide");
      }, 3000);
      setSupportDocType(formdata.question_42);
      setSupportDoc(answerArray[formdata.question_42]);
      setDocType(supportDocTypeArray[formdata.question_42]);
      setUserEmail(formdata.txtEmail);
    }

  }, [splitName]);

 

  state.prev = previous;
  state.closedPrev = stillAct;
  state.IandE = bankStmt ;
  state.q77prev = quest77Previous;


  useEffect(() => {
    if (clickedSlide) {
      dispatch({ type: "showSlide", payload: { clickedSlide } });
      scrollToElement(clickedSlide.slide);
      setTickToElement(clickedSlide);
      setQuestionCounter(questionCounter + 1);
    }

  }, [clickedSlide]);

  const previousSlideChange = (e) => {
    setQuestionCounter((questionCounter) - 1);
    setPreviousSlide({ slide: e.target.getAttribute("name") });
  };

  useEffect(() => {
    if (previousSlide) {
      dispatch({ type: "previousSlides", payload: { previousSlide } });
      scrollToElement(previousSlide.slide);
      // setTimeout(() => {
      //   window.scrollTo(0,0);
      // }, 50);
      slideBack(previousSlide);
      // setQuestionCounter(questionCounter-1);
    }
  }, [previousSlide]);


  const slideBack = (element) => {
    if (element.slide == "btn_qest_44" && (previous == '122' || previous == '195') ) {
      scrollRef.current['confirmPlate']?.classList.remove("active");
    }
    else if (element.slide == "emailConfirm") {
      scrollRef.current["emailConfirmation"]?.classList.remove("active");
    } 
    else {
      scrollRef.current[element.slide]?.classList.remove("active");
    }
   


    if (element.slide == "btn_qest_44" && (previous == '122' || previous == '195') ) {
      scrollRef.current['confirmPlate']?.querySelector(".question-content").classList.remove("hide");
    } else if (element.slide == "emailConfirmation") {
      scrollRef.current['btn_qest_48']?.querySelector(".question-content").classList.remove("hide");
    } 
    else {
      scrollRef.current[element.slide]?.querySelector(".question-content").classList.remove("hide");
    }

    if(element.slide == "goToCheckList") {
      goBack();
    }

  };



  const storeQuestionnire = async (question_data) => {
    const response = await SaveQuestionnaire(
      visitorParameters.visitor_parameters,
      question_data,
      "question_store",
      visitorParameters.data,
      query_string,
      formdata
    );
  };


  const onSelectAnswer = async (e) => {
    let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    let anslabel = e.target.getAttribute("data-ans-label");
    const valuesreg = getValues();
    let employment = splitForm.current["employment"].value;
    let plateNum = valuesreg["question_44"];
    let validEmail = valuesreg["question_48"];
    let dependents = valuesreg["dependents"];
    let didReceiveIncome = valuesreg["didReceiveIncome"];
    let cardMonthlyIncome = valuesreg["cardMonthlyIncome"];
    let confirmPlate = splitForm.current["privatePlate"].value;
    let emailConfirm = splitForm.current["question_61"].value;
    let stillactive = splitForm.current["stillactive"].value;
    let stillHave = splitForm.current["stillVehicle"].value;
    let flage = false;
    let question_data = {};
    setCompletedQuestArr([...completedQuestArr, selectedQuest]);

    if (e.target.name == 'employmentsts' && employment) {
      setClickedSlide({ slide: "employmentsts" });
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: employment,
        answer_text: "",
        input_answer: null,
      };

    } else if (e.target.name == 'dependents' && dependents) {
      setClickedSlide({ slide: "dependents" });
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: dependents,
      };
    } else if (e.target.name == 'didReceiveIncome' && didReceiveIncome) {
      setClickedSlide({ slide: "didReceiveIncome" });
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: didReceiveIncome,
        answer_text: "",
        input_answer: null,
      }; 
    } else if (e.target.name == 'cardMonthlyIncome' && cardMonthlyIncome) {
      setClickedSlide({ slide: "cardMonthlyIncome" });
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: cardMonthlyIncome,
      };  
    } else if (e.target.name == "wereAlreadyPaying") {
      setClickedSlide({ slide: "wereAlreadyPaying" });
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: null,
          answer_text: "",
          input_answer: checklistOptions,
        };
      }
    }
      else if (e.target.name == "btn_qest_48" && validEmail) {
      setClickedSlide({ slide: "btn_qest_48" });
      flage = true;
      question_data = {
        question_id: 48,
        option_id: null,
        answer_text: null,
        input_answer: validEmail,
      };
    }

    else if (e.target.name == "emailConfirmation") {
      if (emailConfirm == "163") {
        totalQuestArr.push(48);
      flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: emailConfirm,
          answer_text: null,
          input_answer: null,
        }
      }else{
        setClickedSlide({ slide: "emailConfirmation",slide_value: emailConfirm});
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: emailConfirm,
        answer_text: null,
        input_answer: null,
      };
      }
      
    } else if (e.target.name == "confirmPlate") {
      if (confirmPlate == "121") {
        setClickedSlide({ slide: "confirmPlate",slide_value: confirmPlate});
        totalQuestArr.push(44);
      flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: confirmPlate,
          answer_text: null,
          input_answer: null,
        }
      }else{
        setClickedSlide({ slide: "confirmPlate",slide_value: confirmPlate});
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: confirmPlate,
        answer_text: null,
        input_answer: null,
      };
      }
      
    } else if (e.target.name == "btn_qest_44" && plateNum) {
      setClickedSlide({ slide: "btn_qest_44" });
      flage = true;
      question_data = {
        question_id: 44,
        option_id: null,
        answer_text: null,
        input_answer: plateNum,
      };
    } else if (e.target.name == 'stillActBtn' && stillactive) {
        setClickedSlide({ slide: e.target.name });
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: stillactive,
          answer_text: null,
          input_answer: "",
        };
      }else if (e.target.name == 'stillHave' && stillHave) {
        setClickedSlide({ slide: e.target.name });
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: stillHave,
          answer_text: null,
          input_answer: "",
        };
      }

    else {
      if (selectedAns && selectedQuest) {
        setClickedSlide({ slide: e.target.name, slide_value: e.target.value });
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: null,
          input_answer: "",
        };
      }
    }
    if (flage) {
      storeQuestionnire(question_data);

      let _existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (_existingEntries == null) _existingEntries = [];
      let existingIndex = _existingEntries.findIndex(entry => entry.question_id === question_data.question_id);
      if (existingIndex !== -1) {
        _existingEntries.splice(existingIndex, 1);
      }
      _existingEntries.push(question_data);
      localStorage.setItem("questionData", JSON.stringify(_existingEntries));

      if (e.target.name == "employmentsts") {
        updateMilestoneNew(uuid,"questions","live");
        const milestone = await updateMilestone(
          uuid,
          "questions",
          "live"
        );
        if (milestone.data.status == "Success") {
          localStorage.setItem("Questionnaire", 1);
          window.location.href = "/preview_MB_UFGN_4?uuid=" + visitorParameters.visitor_parameters.uuid;
          // history.push("/preview_MB_UFGN_4?uuid=" + visitorParameters.visitor_parameters.uuid);
        }
      }
        
    }
  }
  const saveInputAns = async (e) => {
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    let anslabel = e.target.getAttribute("data-ans-label");
    const valuesreg = getValues();
    setCompletedQuestArr([...completedQuestArr, selectedQuest]);
    let flage = false;
    let question_data = {};
    if(e.target.name == "sortCode") {
      setClickedSlide({ slide: e.target.name });
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: null,
          answer_text: null,
          input_answer: sortCodeNumber,
        };
    } else if(e.target.name == "accountNumber") {
      setClickedSlide({ slide: e.target.name });
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: null,
          answer_text: null,
          input_answer: accountNumber,
        };
    }
    if (flage) {
      storeQuestionnire(question_data);
      // var existingEntries = JSON.parse(localStorage.getItem("questionData"));
      // if (existingEntries == null) existingEntries = [];
      // localStorage.setItem(
      //   "currentquestionData",
      //   JSON.stringify(question_data)
      // );
      // existingEntries.push(question_data);
      // localStorage.setItem("questionData", JSON.stringify(existingEntries));

      let _existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (_existingEntries == null) _existingEntries = [];
      let existingIndex = _existingEntries.findIndex(entry => entry.question_id === question_data.question_id);
      if (existingIndex !== -1) {
        _existingEntries.splice(existingIndex, 1);
      }
      _existingEntries.push(question_data);
      localStorage.setItem("questionData", JSON.stringify(_existingEntries));


  
    }


  }

  const saveSlide4 = async (e) => {
    let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));

    setCompletedQuestArr([...completedQuestArr, selectedQuest]);

    var answer31 = splitForm.current["question_31"].value;
    var answer32 = splitForm.current["question_32"].value;
    var answer33 = splitForm.current["question_33"].value;
    var answer35 = splitForm.current["question_35"].value;


    // let answer57 = splitForm.current["question_57"].value;
    // let answer58 = splitForm.current["question_58"].value;
    // let answer59 = splitForm.current["question_59"].value;
    // let answer60 = splitForm.current["question_60"].value;

    var slide_array = [
      ["31", answer31],
      ["32", answer32],
      ["33", answer33],
      ["35", answer35],
      // ["57", answer57],
      // ["58", answer58]
    ];


    var question_data = {
      slide_4: slide_array,
    };
    setClickedSlide({ slide: "incmAndMonth" });
    storeQuestionnire(question_data);

    let _existingEntries = JSON.parse(localStorage.getItem("questionData"));
    if (_existingEntries == null) _existingEntries = [];
    let existingIndex = _existingEntries.findIndex(entry => entry.question_id === question_data.question_id);
    if (existingIndex !== -1) {
      _existingEntries.splice(existingIndex, 1);
    }
    _existingEntries.push(question_data);
    localStorage.setItem("questionData", JSON.stringify(_existingEntries));
  };

  const saveSlide80 = async (e) => {
    let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
  
    setCompletedQuestArr([...completedQuestArr, selectedQuest]);
  
    const values = getValues();
    let slide_array = [];
  
    if (checklistOptions.some((option) => option.id === 228)) {
      let answer228 = splitForm.current["question_228_229"].value;
      slide_array.push(["228", answer228]);
    }
    if (checklistOptions.some((option) => option.id === 229)) {
      let answer229 = splitForm.current["question_228_229"].value;
      slide_array.push(["229", answer229]);
    }
    if (checklistOptions.some((option) => option.id === 230)) {
      let answer230 = splitForm.current["question_230"].value;
      slide_array.push(["230", answer230]);
    }
    if (checklistOptions.some((option) => option.id === 231)) {
      let answer231 = splitForm.current["question_231"].value;
      slide_array.push(["231", answer231]);
    }
    if (checklistOptions.some((option) => option.id === 232)) {
      let answer232 = splitForm.current["question_232"].value;
      slide_array.push(["232", answer232]);
    }
    if (checklistOptions.some((option) => option.id === 233)) {
      let answer233 = splitForm.current["question_233"].value;
      slide_array.push(["233", answer233]);
    }
    if (checklistOptions.some((option) => option.id === 234)) {
      let answer234 = splitForm.current["question_234"].value;
      slide_array.push(["234", answer234]);
    }
    if (checklistOptions.some((option) => option.id === 235)) {
      let answer235 = splitForm.current["question_235"].value;
      slide_array.push(["235", answer235]);
    }

  
    var question_data = {
      slide_80: ["80",JSON.stringify(slide_array)],
    };
  
    setClickedSlide({ slide: "incmAndMonth" });
    storeQuestionnire(question_data);
  
    let _existingEntries = JSON.parse(localStorage.getItem("questionData"));
    if (_existingEntries == null) _existingEntries = [];
    let existingIndex = _existingEntries.findIndex(entry => entry.question_id === question_data.question_id);
    if (existingIndex !== -1) {
      _existingEntries.splice(existingIndex, 1);
    }
    _existingEntries.push(question_data);
    localStorage.setItem("questionData", JSON.stringify(_existingEntries));
  };

  const saveIncomeAndExpense = (checklistOptions, inputListValues) => {
    var question_data_79 = {
      question_id: 79,
      option_id: null,
      answer_text: null,
      input_answer: checklistOptions,
    };
    var question_data_80 = {
      slide_80: ["80", JSON.stringify(inputListValues)],
    };


    setClickedSlide({ slide: "wereAlreadyPaying" });
    storeQuestionnire(question_data_79);
    storeQuestionnire(question_data_80);
    setDataToLocalStorage(79, question_data_79);
    setDataToLocalStorage(80, question_data_80);
  }

  const setDataToLocalStorage = (quest_id, quest_data) => {
    let _existingEntries = JSON.parse(localStorage.getItem("questionData"));
    if (_existingEntries == null) _existingEntries = [];
    let existingIndex;
    if(quest_id == 80) {
      existingIndex = _existingEntries.findIndex(entry => (entry?.slide_80));
    } else {
      existingIndex = _existingEntries.findIndex(entry => entry.question_id == quest_id);
    }
    if (existingIndex !== -1) {
      _existingEntries.splice(existingIndex, 1);
    }
    _existingEntries.push(quest_data);
    localStorage.setItem("questionData", JSON.stringify(_existingEntries));
  }

  useEffect(() => {

    var value = questionCounter;
    if (value == 1) {
      SetPercentage(0);
    } else {
      SetPercentage(
        Math.round(((value - 1) * 100) / totalQuestArr.length)
      );
    }


  }, [questionCounter]);

  const scrollToElement = (element) => {

    scrollRef.current[element]?.scrollIntoView({ behavior: "smooth", block: 'start', inline: 'center' });
  };

  const setTickToElement = (element) => {
    if (element.slide == "emailConfirmation") {
      scrollRef.current['btn_qest_48']?.classList.add("active");
    } else {
      scrollRef.current[element.slide]?.classList.add("active");
    }
    if (element.slide == "emailConfirmation" && (element.slide_value == '162' || element.slide_value == '195')) {
      scrollRef.current['btn_qest_48']?.querySelector(".question-content").classList.add("hide");
    }

    scrollRef.current[element.slide]?.querySelector(".question-content").classList.add("hide");
    // scrollRef.current[element]?.querySelector(".tipbox").classList.add("hide");
  };
  

  

  const SkippedLeads = async (type) => {
    const skippedLeadsResponse = await skippedLeads(type, uuid, splitName);
    let array = JSON.parse(localStorage.getItem('questionData'));
    if(type == "skipAccountNumber") {
      array = array.filter(item => item.question_id !== 87);
      setClickedSlide({ slide: 'accountNumber' });
    } else if(type == "skipSortCode") {
      array = array.filter(item => item.question_id !== 86);
      setClickedSlide({ slide: 'sortCode' });
    }
    localStorage.setItem('questionData', JSON.stringify(array));
  }
  
  const skipAgNo = async (e) => {
    setAgNoShow('hide');
    SkippedLeads("SkipAgreementNo");
  }

  return (
    <>
      <GetVisitorsParams />

      <div className="questionnaire21">
        <section className="banner lenderSection">
          <div className="container">
            <div>
              <div className="brand">
                <div className="logo pt-3 text-center">
                  <a>
                    {" "}
                    <img src={logowhite} style={{ height: 60 }} alt=""></img>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="container mt-5 ">
            <Loader showUserName={showUserName} showLoad={showLoad} />

            <div
              id="quetWraper"
              className="questionnair-section form-style pb-5"
              style={
                showLoad === "hide"
                  ? { display: "block" }
                  : { display: "none " }
              }
            >
              {/* <div id="rangequestions" className="qrange d-flex align-items-sm-center text-center">
                  Completed
                  <br className="giveSpace" /> 
                  &nbsp;{percentage}%
                </div> */}

              <form
                ref={splitForm}
                name="split_form"
                id="user_form"
                autoComplete="off"
              >
                <ul className="p-0 m-0 questlist-two mb-5">
                  {/* <li>
                    <div className={`questitem q14 ${state.showSlide1}`} id="slide1" ref={(el) => (scrollRef.current['incmAndMonth'] = el)}>
                      <h1 className="que-title p-3">Income and Expenditure Details </h1>
                      <IncomeAndExp
                        validation={register}
                        validationMsg={errors}
                        getValues={getValues}
                        trigger={trigger}
                        setError={setError}
                        splitForm={splitForm}
                        questArr={questinnare1}
                        saveSlide4={saveSlide4}
                        scrollToElement={scrollToElement}
                        backClick={previousSlideChange}
                        obValue={obValue}
                        goBack={goBack}
                      />
                    </div>
                    <div className={`tipbox tip2 ${state.showSlide1a}`}>
                      This helps us in evaluating your monthly income and expenditures.
                      <div className="arrow  arrow-top" />
                    </div>

                  </li> */}
                  <li>
                    <div
                      className={`questitem ${state.showSlide_DidReceiveIncome}`}
                      id="slide-23"
                      ref={(el) => (scrollRef.current["didReceiveIncome"] = el)}
                    >
                      <h1 className="que-title p-3 ">
                        In the year when you took the car finance - did you
                        receive any income?
                      </h1>
                      <DidReceiveIncome
                        onSelectAnswer={onSelectAnswer}
                        validation={register}
                        backClick={previousSlideChange}
                        setquest77Previous={setquest77Previous}
                      />
                    </div>
                    {/* <div className={`tipbox tip2 ${state.showSlide23a}`}>
                      This helps us in evaluating your monthly income and
                      expenditures..
                      <div className="arrow  arrow-top" />
                    </div> */}
                  </li>
                  <li>
                    <div
                      className={`questitem ${state.showSlide_CardMonthlyIncome}`}
                      id="slide-24"
                      ref={(el) =>
                        (scrollRef.current["cardMonthlyIncome"] = el)
                      }
                    >
                      <h1 className="que-title p-3 ">
                        When you took out your car finance what was your monthly
                        income?
                      </h1>
                      <CardMonthlyIncome
                        onSelectAnswer={onSelectAnswer}
                        validation={register}
                        clearErrors={clearErrors}
                        trigger={trigger}
                        validationMsg={errors}
                        getValues={getValues}
                        backClick={previousSlideChange}
                      />
                    </div>
                    {/* <div className={`tipbox tip2 ${state.showSlide24a}`}>
                      If you're unsure,please select "I Don't Recall" to avoid
                      neagatively effecting the claim.
                      <div className="arrow  arrow-top" />
                    </div> */}
                  </li>
                  <li>
                    <div
                      className={`questitem  ${state.showSlide_DependentsV2}`}
                      id="slide-9"
                      ref={(el) => (scrollRef.current["dependents"] = el)}
                    >
                      <h1 className="que-title p-3 ">
                        How many dependents did you have at that time?{" "}
                      </h1>
                      <DependentsV2
                        onSelectAnswer={onSelectAnswer}
                        didReceiveIncome={quest77Previous}
                        validation={register}
                        clearErrors={clearErrors}
                        trigger={trigger}
                        validationMsg={errors}
                        getValues={getValues}
                        backClick={previousSlideChange}
                      />
                    </div>
                    {/* <div className={`tipbox tip2 ${state.showSlide9a}`}>
                    We need to determine the number of dependents in order to assess and evaluate your financial position.
                      <div className="arrow  arrow-top" />
                    </div> */}
                  </li>
                  <li>
                    <div
                      className={`questitem q25 ${state.showSlide_WereAlreadyPaying}`}
                      id="slide-25"
                      ref={(el) =>
                        (scrollRef.current["wereAlreadyPaying"] = el)
                      }
                    >
                      <h1 className="que-title p-3">
                        When you took out the car finance, were you already
                        paying monthly for one or more of the following:
                      </h1>
                      {/* <WereAlreadyPaying
                        onSelectAnswer={onSelectAnswer}
                        questArr={wereUalreadyPaying}
                        validation={register}
                        validationMsg={errors}
                        getValues={getValues}
                        trigger={trigger}
                        setError={setError}
                        checklistOptions={checklistOptions}
                        backClick={previousSlideChange}
                        setChecklistOptions={setChecklistOptions}
                      />
                      <br /> */}
                      <IncomeAndExpV3
                        /* checklistOptions={checklistOptions}
                        setChecklistOptions={setChecklistOptions}
                        inputListValues={inputListValues}
                        setInputListValues={setInputListValues} */
                        backClick={previousSlideChange}
                        saveIncomeAndExpense={saveIncomeAndExpense}
                      />
                    </div>
                    {/* <div className={`tipbox tip2 ${state.showSlide25a}`}>
                     Select all that you had
                      <div className="arrow  arrow-top" />
                    </div> */}
                  </li>

                  <li>
                    <div
                      className={`questitem q26 ${state.showSlide_SortCode}`}
                      id="slide-26"
                      ref={(el) => (scrollRef.current["sortCode"] = el)}
                    >
                      <h1 className="que-title p-3">
                        Please provide your Sort Code
                      </h1>
                      
                      <SortCode
                        backClick={previousSlideChange}
                        saveInputAns={saveInputAns}
                        setSortCodeNumber={setSortCodeNumber}
                        SkippedLeads={SkippedLeads}
                        lenderName={lenderName}
                      />
                    </div>
                  </li>
                  <li>
                    <div
                      className={`questitem q26 ${state.showSlide_AccountNumber}`}
                      id="slide-26"
                      ref={(el) => (scrollRef.current["accountNumber"] = el)}
                    >
                      <h1 className="que-title p-3">
                        Please provide your Bank Account Number
                      </h1>
                    

                      <AccountNumber
                        backClick={previousSlideChange}
                        saveInputAns={saveInputAns}
                        setAccountNumber={setAccountNumber}
                        SkippedLeads={SkippedLeads}
                        lenderName={lenderName}

                      />
                    </div>
                  </li>
                  <li>
                    <div
                      className={`questitem q26 ${state.showSlide_IncomeAndExpV2}`}
                      id="slide-26"
                      ref={(el) => (scrollRef.current["incmAndMonth"] = el)}
                    >
                      <h1 className="que-title p-3">
                        {" "}
                        Please confirm the following MONTHLY / ANNUAL income and
                        expenditure details
                      </h1>
                      <IncomeAndExpV2
                        validation={register}
                        validationMsg={errors}
                        getValues={getValues}
                        trigger={trigger}
                        setError={setError}
                        splitForm={splitForm}
                        questArr={wereUalreadyPaying}
                        saveSlide80={saveSlide80}
                        scrollToElement={scrollToElement}
                        backClick={previousSlideChange}
                        checklistOptions={checklistOptions}
                      />
                    </div>
                    {/* <div className={`tipbox tip2 ${state.showSlide26a}`}>
                      slide26a
                      <div className="arrow  arrow-top" />
                    </div> */}
                  </li>
                  <li>
                    <div
                      className={`questitem q15 ${state.showSlide2}`}
                      id="slide2"
                      ref={(el) => (scrollRef.current["btn_qest_48"] = el)}
                    >
                      <div
                        className={
                          isRegisteredEmail == "hide" ? "hide" : "show"
                        }
                      >
                        <h1 className="que-title p-3 regemail">
                          Is this the email address {userEmail} that you
                          registered with MoneyBarn
                        </h1>
                      </div>
                      <div
                        className={
                          isNewEmailVisible == "show" ? "show" : "hide"
                        }
                      >
                        <h1 className="p-3 newemail que-title">
                          Please provide the e-mail address you registered with
                          MoneyBarn
                        </h1>
                      </div>
                      <Emailconfirmation
                        onSelectAnswer={onSelectAnswer}
                        consentStatus={consentStatus}
                        splitForm={splitForm}
                        getValues={getValues}
                        setIsRegisteredEmail={setIsRegisteredEmail}
                        setIsNewEmailVisible={setIsNewEmailVisible}
                        isRegisteredEmail={isRegisteredEmail}
                        isNewEmailVisible={isNewEmailVisible}
                        validation={register}
                        clearErrors={clearErrors}
                        trigger={trigger}
                        validationMsg={errors}
                        backClick={previousSlideChange}
                        bankStmt={bankStmt}
                        obValue2={obValue2}
                        goBack={goBack}
                      />
                    </div>
                    {/* <div className={`tipbox tip2 ${state.showSlide2a}`}>
                      This is required to cross confirm with Vanquis database and for effective communication.
                      <div className="arrow  arrow-top" />
                    </div> */}
                  </li>
                  <li>
                    <div
                      className={`questitem ${state.showSlide3}`}
                      id="slide3"
                      ref={(el) => (scrollRef.current["confirmPlate"] = el)}
                    >
                      <h1 className="que-title p-3 ">
                        {" "}
                        Have you registered a Private Plate?
                      </h1>
                      <PrivatePlate
                        validation={register}
                        validationMsg={errors}
                        getValues={getValues}
                        trigger={trigger}
                        setError={setError}
                        onSelectAnswer={onSelectAnswer}
                        splitForm={splitForm}
                        scrollToElement={scrollToElement}
                        backClick={previousSlideChange}
                        setPrevious={setPrevious}
                      />
                    </div>
                    {/* <div className={`tipbox tip2 ${state.showSlide3a}`}>
                      This is to determine the status of your Vanquis account.
                      <div className="arrow  arrow-top" />
                    </div> */}
                  </li>

                  <li>
                    <div
                      className={`questitem ${state.showSlide4}`}
                      id="slide3"
                      ref={(el) => (scrollRef.current["btn_qest_44"] = el)}
                    >
                      <h1 className="que-title p-3 ">
                        {" "}
                        Please enter your Privately Registered Plate
                      </h1>
                      <PrivatePlateNum
                        validation={register}
                        validationMsg={errors}
                        getValues={getValues}
                        trigger={trigger}
                        setError={setError}
                        splitForm={splitForm}
                        scrollToElement={scrollToElement}
                        backClick={previousSlideChange}
                        onSelectAnswer={onSelectAnswer}
                      />
                    </div>
                    {/* <div className={`tipbox tip2 ${state.showSlide4a}`}>
                      This is to determine the status of your Vanquis account.
                      <div className="arrow  arrow-top" />
                    </div> */}
                  </li>

                  <li>
                    <div
                      className={`questitem ${state.showSlide5}`}
                      id="slide4"
                      ref={(el) => (scrollRef.current["stillActBtn"] = el)}
                    >
                      <h1 className="que-title p-3 ">
                        {" "}
                        Is your finance agreement with MoneyBarn still active?
                      </h1>
                      <StillActive
                        onSelectAnswer={onSelectAnswer}
                        backClick={previousSlideChange}
                        splitForm={splitForm}
                      />
                    </div>
                    {/* <div className={`tipbox tip2 ${state.showSlide5a}`}>
                      This is to determine the status of your Vanquis account.
                      <div className="arrow  arrow-top" />
                    </div> */}
                  </li>

                  <li>
                    <div
                      className={`questitem ${state.showSlide6}`}
                      id=""
                      ref={(el) => (scrollRef.current["stillHave"] = el)}
                    >
                      <h1 className="que-title p-3 ">
                        {" "}
                        Do you still have the vehicle?
                      </h1>
                      <StillHaveVehicle
                        onSelectAnswer={onSelectAnswer}
                        backClick={previousSlideChange}
                        splitForm={splitForm}
                      />
                    </div>
                    {/* <div className={`tipbox tip2 ${state.showSlide6a}`}>
                      This is to determine the status of your Vanquis account.
                      <div className="arrow  arrow-top" />
                    </div> */}
                  </li>

                  <li id="lop">
                    <div
                      className={`questitem ${state.showSlide7}`}
                      id="slide-8"
                      ref={(el) => (scrollRef.current["employmentsts"] = el)}
                    >
                      <h1 className="que-title p-3 ">
                        At the time you secured finance, what was your
                        employment status?{" "}
                      </h1>
                      <EmploymentStatus
                        onSelectAnswer={onSelectAnswer}
                        validation={register}
                        clearErrors={clearErrors}
                        trigger={trigger}
                        validationMsg={errors}
                        getValues={getValues}
                        backClick={previousSlideChange}
                        splitForm={splitForm}
                      />
                    </div>
                    {/* <div className={`tipbox tip2 ${state.showSlide7a}`}>
                      We require details regarding your employment status at the time you obtained the loan, as it aids in assessing your financial situation..
                      <div className="arrow  arrow-top" />
                    </div> */}
                  </li>
                  {/* <li>
                    <div className={`questitem  ${state.showSlide8}`} id="slide-9" ref={(el) => (scrollRef.current['dependents'] = el)}>
                      <h1 className="que-title p-3 ">How many dependents did you have at that time? </h1>
                      <Dependents
                        onSelectAnswer={onSelectAnswer}
                        validation={register}
                        clearErrors={clearErrors}
                        trigger={trigger}
                        validationMsg={errors}
                        getValues={getValues}
                        splitForm={splitForm}
                        backClick={previousSlideChange}
                      />
                    </div>
                    <div className={`tipbox tip2 ${state.showSlide9a}`}>
                      We need to determine the number of dependents in order to assess and evaluate your financial position.
                      <div className="arrow  arrow-top" />
                    </div>
                  </li> */}
                  {/* <li>
                  <div className="questitem " id="slide9">
                    <h1 className="que-title p-3 ">Have you experienced any hardships or struggle in making the repayments to
                      Vanquis on time while still meeting all your other commitments? </h1>
                      <Struggle
                      clsName={`${state.showSlide10}`}
                      onSelectAnswer={onSelectAnswer}
                      />
                  </div>
                </li> */}

                  {/* <li>
                    <div className={`questitem q16 ${state.showSlide16}`} id="slide-16" ref={(el) => (scrollRef.current['question_42'] = el)}>
                      <h1 className="que-title p-3 ">What supporting documentation are you able to provide to bolster your complaint with Vanquis?</h1>
                      <SupportingDoc
                        onSelectAnswer={onSelectAnswer}
                        backClick={previousSlideChange}

                      />
                    </div>
                    <div className={`tipbox tip2 ${state.showSlide16a}`}>
                      Supporting documents provide evidence to validate the claim being made, and provide necessary information for decision-making processes.
                      <div className="arrow  arrow-top" />
                    </div>

                  </li> */}
                  {/* <li>
                    <div className={`questitem q17 ${state.showSlide17}`} id="slide-17">
                      <h1 className="que-title p-3 ">Supporting Documentation</h1>
                      <SupportingDocUpload
                        saveInputAns={saveInputAns}
                        validation={register}
                        validationMsg={errors}
                        getValues={getValues}
                        trigger={trigger}
                        setError={setError}
                        backClick={previousSlideChange}
                        pushPageChange={pushPageChangeV2}
                        docType={docType}
                        split_name={splitNameForSkip}
                        uuid={uuid}
                        onSelectAnswer={onSelectAnswer}
                      />
                    </div>
                    <div className={`tipbox tip2 ${state.showSlide17a}`}>
                      Uploading any Supporting documents enable us to understand your financial status.
                      <div className="arrow  arrow-top" />
                    </div>

                  </li> */}
                </ul>
              </form>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </>
  );
}
export default QuestionnaireGN_4;