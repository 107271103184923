import React, { useState } from "react";
import OtpInputBox from "../../../UI/OtpInputBox";

const BankAccNo = ({
  saveInputAns,
  backClick,
  SkippedLeads,
  setClickedSlide,
  setAccNum,
  lenderName
}) => {
  const [accNo, setAccNo] = useState("");
  const [error, setError] = useState(false);

  const handlebtnChange = (accNo) => {
    setAccNo(accNo);
    setAccNum(accNo);
  };
  const skipNext = () => {
    let array = JSON.parse(localStorage.getItem('questionData'));
    array = array.filter(item => item.question_id !== 87);
    localStorage.setItem('questionData', JSON.stringify(array));
    SkippedLeads("skipAccountNumber",false);
    setClickedSlide({ slide : "bank_acc_no" });
  };

  const handleNext = async (e) => {
    if (accNo.length != 8) {
      setError(true);
      return;
    } else if (parseInt(accNo) === 0) {
      setError(true);
      return;
    } else {
      setError(false);
      saveInputAns(e);
    }
  };

  return (
    <div className='question-content'>
      {("others" !== lenderName && "" !== lenderName) && (
        <h1 className="que-title p-3">
          Lender : {lenderName}
        </h1>
      )}
      <div className="mt-0 scale-down-ver-center px-3 pb-lg-3 pb-md-3 pb-sm-2 pb-2">
        <div className="row">
          <div className="col-lg-8 mb-2">
            <div className="otp-input">
              <OtpInputBox
                length={8}
                onChange={handlebtnChange}
                className={"otp-input-field"}
              />
            </div>
            {error && (
              <span className="error_msg mt-2">
                Please enter a valid Bank Account Number
              </span>
            )}
          </div>
          <div className="col-lg-4 mb-2 ">
            <button
              type="button"
              className=" btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn "
              id="nextscroll"
              name="bank_acc_no"
              onClick={handleNext}
              data-quest-id={87}
            >
              Next &#8594;
            </button>
          </div>
          <span className="warning-text">
            {
              "(Please confirm your Bank Account Number to expedite your claim. If you prefer not to, you can skip this step.)"
            }
          </span>
          <span className="col-12 text-center mt-1">
            <img
              src={"../../../../assets/Quest_VAQ_UB_4_3/img/secure_icon.png"}
              style={{ width: "81px" }}
            />
          </span>
        </div>
        <div className="row">
          <div className="mb-2 mt-3 text-center">
            <a
              className="backbtn skip-btn"
              name="bank_acc_no"
              onClick={skipNext}
            >
              {"Skip"}
            </a>
          </div>
        </div>
        <p className="text-center bck-marwid">
          <button
            name="sortcode"
            className="btn btn-secondary backbtn1a"
            id=""
            value=""
            type="button"
            onClick={backClick}
          >
            Go Back
          </button>
        </p>
      </div>
    </div>
  );
};

export default BankAccNo;
