import React, {
    useState,
    useReducer,
    useEffect,
  } from "react";
  import LOA from '../../../../assets/pdf/LOA_Vanquis.pdf';
  import TOB from '../../../../assets/pdf/Business_Terms_TMS.pdf';
  import VQ from '../../../../assets/pdf/Questionnaire_Vanquis.pdf';
  import DSAR from '../../../../assets/pdf/MoneyBarn_DSAR.pdf';
  import DECL from '../../../../assets/pdf/Vaq_Declaration.pdf';
  import { useClaimReg } from "../../../../Hooks/useClaimReg";
  import CheckBox2 from "../../../UI/CheckBox2"
  
  const SignatureConfirm = ({ allFollowupData, uuid, userSign, cfirstName, removeFromPending, addPending }) => {
    const flp_source = allFollowupData.atp_sub6;
    const { signatureConfirmVaqV2Flp } = useClaimReg();
    const [isChecked, setIsChecked] = useState(true);
    const [errorMsg, setErrorMsg] = useState(false);
    const modalChange = async () => {
      if(isChecked){
        const response = await signatureConfirmVaqV2Flp(
          uuid,
          "followup-vaq",
          "signature_confirm",
          allFollowupData.atp_sub6
          );          
          if (response.data.status === "Success") {
            removeFromPending("signature_confirm");
          }
      }
    }

    const retakeSign = () => {
      removeFromPending("signature_confirm");
      addPending("signature");
    };

    useEffect(() => {
      if (isChecked) {
        setErrorMsg(false);
      } else{
        setErrorMsg(true);
      }
    }, [isChecked]);

    return (
      <>
        <div className="signinpopup">
          <div
            className={`modal blackover fade show`}
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg modal-dialog-centered">
              <div className="modal-content signinpopup">
                <div className="modal-body">
                  <div className="row align-items-center text-center" >
                    <h2>IMPORTANT</h2>
                    <p>
                      <b>
                      Our digital signature appears outdated. Please confirm if it's still valid or provide an update so we can proceed with your claim. Thank you!
                      </b>
                    </p>
                    <div className="popup-sign-sec">
                      <div className="signbox" style={{ minHeight: '225px' }}>
                      <div className= "text-center">
                        <div id="preview" className="web-canvas">
                          <img width="300" height="250" src={userSign} />
                        </div>
                        {errorMsg && (
                        <div className="sign_msg col-12 text-center">
                            Please tick to show your authorisation for us to proceed
                        </div>
                      )}
                        <div className="input-group mb-3 justify-content-lg-center justify-content-between">
                          <div className="mb-3 mt-3 col-5 p-0">
                            <input
                              type="button"
                              name="clearbutton"
                              id="clearbutton"
                              className="btn btn-secondary"
                              defaultValue="Retake"
                              onClick={retakeSign}
                            />
                          </div>
                          <div className="mb-3 mt-3 col-7 p-0">
                            <input
                              type="button"
                              name="signbutton"
                              id="signbutton"
                              className="signbutton"
                              defaultValue="I Confirm"
                              onClick={modalChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-check my-3 d-flex">
                        <CheckBox2
                          dataId="acceptterms_flp"
                          anchorText=""
                          anchorStyle=""
                          href=""
                          onChange={(e) => setIsChecked(!isChecked)}
                          className="form-check-input"
                          labelClassName="form-check-label sign-custom-control-label"
                          value={isChecked}
                          checked={isChecked}
                          CheckBoxText="By clicking submit you agree to us appending your electronic signature to the claim documents and confirming that
                          you accept the updated terms of this contract for TMS Legal to represent you in your unaffordable/irresponsible lending claim."
                        />
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <ul>
                        <li>
                          <a href={LOA} target="_blank">
                            <i className="bi bi-arrow-up-right-square" /> Letter
                            of Authority
                          </a>
                        </li>
                        <li>
                          <a href={TOB} target="_blank">
                            <i className="bi bi-arrow-up-right-square" /> Terms of Business Agreement
                          </a>
                        </li>
                        <li>
                          <a href={VQ} target="_blank">
                            <i className="bi bi-arrow-up-right-square" />{" "}
                            Questionnaire
                          </a>
                        </li>
                        <li>
                          <a href={DECL} target="_blank">
                            <i className="bi bi-arrow-up-right-square" />{" "}
                            Declaration Form
                          </a>
                        </li>
                        <li>
                          <a href={DSAR} target="_blank">
                            <i className="bi bi-arrow-up-right-square" />{" "}
                            DSAR Letter of Authority
                          </a>
                        </li>
                      </ul>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
    );
  };
  export default SignatureConfirm;
  