import { useEffect, useState } from "react";
import { ClosedYears2 } from "../../../../Constants/Constants";
import SelectBox from "../../../UI/SelectBox";
import InputButton from "../../../UI/InputButton";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import Autocomplete from "./AutoComplete";

const Banks = ({
  clsName,
  saveInputAns,
  validationMsg,
  clearErrors,
  validation,
  trigger,
  getValues,
  backClick,
  SelectBank,
  setSelectBank,
  lenderList,
  oldCard,
  latePay,
  setLenderName,
  setError
}) => {
  const [BankValidationMsg, setBankValidationMsg] = useState(false);
  const [showOtherOpts, setShowOtherOpts] = useState(false);

  const customStyles = {
    control: base => ({
      ...base,
      //height: 50,
      minHeight: 50
    })
  };


  const nextSlide = async (e) => {
    const values = getValues();
    console.log(values);

    let LengthSelectedBank = SelectBank.length;
    let ValueSelectedBank = SelectBank.value;
    let LabelSelectedBank = SelectBank.label;
    if (LengthSelectedBank < 1 || ValueSelectedBank == "") {
      setError("lenders", {
        type: "manual",
        message: "Please Enter Valid Bank Name",
      });
      setBankValidationMsg(true);
    }
    else {
      const input = values["lenders"];
      const otherlender = await trigger("lenders");

      if (input != "" && otherlender) {
        localStorage.setItem("bud_lender", input);
        saveInputAns(e);
      } else {
        setError("lenders", {
          type: "manual",
          message: "Please Enter Valid Bank Name",
        });
      }
    }
  }

  let BankList = [];
  if (lenderList.length > 0) {
    BankList = BankList.concat(lenderList.filter(function (val) {
      return !val.display_name.endsWith("Card") &&
        !val.display_name.endsWith("Business") &&
        !val.display_name.endsWith("card") &&
        !val.display_name.endsWith("Cards") &&
        !val.display_name.includes("Others")
    }).map(function (val) {
      return val.display_name
    }));
  }

  const OtherLenderSlide = async (e) => {
    e.preventDefault();

    const input = getValues('otherlenders');
    const otherlender = await trigger("otherlenders");

    if (input !== "" && otherlender) {
      localStorage.setItem("bud_lender", input);
      // setLenderName(input);
      saveInputAns(e);

    } else {
      setError("otherlenders", {
        type: "manual",
        message: "Please Enter Valid Bank Name",
      });
    }

  }

  useEffect(() => {
    if (SelectBank.value !== 'others') {
      setShowOtherOpts(false);
    }
  }, [SelectBank.value]);


  return (
    <>
      <div className="question-content mt-0 scale-down-ver-center  px-3 py-lg-3 py-md-3 py-sm-2 py-2">
        <div className="row">
        <div className="col-lg-12 mb-2">
            <div className="row">
              <div className="col-lg-8 mb-2 z-2 autocomplete-wrapper">
                <Autocomplete
                  validation={validation}
                  clearErrors={clearErrors}
                  suggestionList={BankList}
                  setSelectBank={setSelectBank}
                />
              </div>

              <div className="col-lg-4 mb-2">
                <button
                  type="button"
                  className=" btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
                  id="submitLender"
                  onClick={nextSlide}
                  name="banks"
                  data-quest-id={64}
                >
                  Next &#8594;
                </button>
              </div>
            </div>
            {validationMsg.lenders && (
              <span id="bank_err" className="error_msg">
                {validationMsg.lenders.message}
              </span>
            )}
          </div>

        </div>
        <p className="text-center bck-marwid">
          <button
            name='btn_qest_76'
            className="btn btn-secondary backbtn1a"
            id=""
            value=""
            type="button"
            onClick={backClick}

          >Go Back</button>
        </p>
      </div>

    </>
  )
}
export default Banks;