import React from "react";
import loader_review2 from "../../../../assets/FollowupVaqV2/img/loader-review2.svg";
const Loader = ({ showUserName, showLoad }) => {
    return (
        <>
            <div className="container d-flex align-items-center justify-content-center vh-100">
                <div className={`loader-wrap text-center text-light ${showLoad ? "show" : 'hide'} `}>
                    <div>
                        <img style={{ height: 80 }} src={loader_review2} alt="" />
                        {showUserName && (
                            <>
                                <h6 className="mt-3">
                                    {showUserName} we need a few more details before we can proceed.
                                </h6>
                                <p>(Don't worry, it will only take a minute.)</p>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}
export default Loader;