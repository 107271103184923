import React, { useState } from 'react';
import OtpInputBox from '../../../UI/OtpInputBox';
import secureSSLImg from '../../../../assets/Questionnaire21/img/secure_icon.png'

const SortCode = ({
    saveInputAns,
    backClick,
    setSortCodeNumber,
    SkippedLeads,
    lenderName
}) => {
  const [sortCode, setSortCode] = useState("");
  const [error, setError] = useState(false);
  const OBStatementValue = localStorage.getItem("OBStatement");

  const handleOtpChange = (sortCode) => {
    setSortCode(sortCode);
    setSortCodeNumber(sortCode)
  };

  const handleNext = async (e) => {
    if(sortCode.length != 6) {
        setError(true);
        return;
    } else if(parseInt(sortCode) === 0){
        setError(true);
        return;
    } else {
        setError(false)
        saveInputAns(e);
    }
  };
  return (
    <div className='question-content'>
      {("others" !== lenderName && "" !== lenderName) && (
        <h1 className="que-title p-3">
          Lender : {lenderName}
        </h1>
      )}
      <div className="mt-0 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2">
        <div className="row">
          <div className="col-lg-8 mb-2">
            <div className="otp-input">
              <OtpInputBox
                length={6}
                onChange={handleOtpChange}
                className={"otp-input-field"}
              />
            </div>
            {error && (
              <span className="error_msg mt-2 text-center">
                Please enter a valid Sort Code
              </span>
            )}
          </div>
          <div className="col-lg-4 mb-2 ">
            <button
              type="button"
              className="btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn "
              id="nextscroll"
              name="sortCode"
              onClick={handleNext}
              data-quest-id={86}
            >
              Next &#8594;
            </button>
          </div>
          <div className="text-center">
            <span className="warning-text mb-2">
              {
                "(Please confirm your Sort Code to expedite your claim. If you prefer not to, you can skip this step.)"
              }
            </span>
            <br />
            <img src={secureSSLImg} style={{ width: "81px" }} />
          </div>
        </div>
        <div className="row">
          <div className="text-center mt-2">
            <a
              className="backbtn skip-btn"
              name="skipSortCode"
              onClick={() => {
                SkippedLeads("skipSortCode");
              }}
            >
              Skip
            </a>
          </div>
        </div>
        <div className="row">
          <div className="text-center mt-2">
            {OBStatementValue == 1 ? (
              <div
                className="text-primary back-btn"
                name="goToCheckList"
                onClick={backClick}
              >
                <i className="bi bi-arrow-left"></i> Go Back
              </div>
            ) : (
              <div
                className="backbtn"
                name="wereAlreadyPaying"
                onClick={backClick}
              >
                <i className="bi bi-arrow-left"></i> Back
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SortCode;

