import React, {
  useRef,
  useState,
  useReducer,
  useEffect,
} from "react";

import SignatureCanvas from "react-signature-canvas";
import { useFollowupUserSignature } from "../../../../Hooks/useFollowupUserSignature";
import LOA from '../../../../assets/pdf/LOA_Vanquis.pdf';
import TOB from '../../../../assets/pdf/Business_Terms_TMS.pdf';
import VQ from '../../../../assets/pdf/Questionnaire_Vanquis.pdf';
import DSAR from '../../../../assets/pdf/MoneyBarn_DSAR.pdf';
import { useUpdateMilestone } from "../../../../Hooks/useUpdateMilestone";
import DECL from '../../../../assets/pdf/Vaq_Declaration.pdf';
import CheckBox2 from "../../../UI/CheckBox2";
import { useClaimReg } from "../../../../Hooks/useClaimReg";


const initialState = {
  isChecked: false,
  isAgreed: false,
  isCanvasEmpty: false,
  isValid: false,
};
const signatureReducer = (state, action) => {
  switch (action.type) {
    case "signPadChange": {
      return {
        ...state,
        isCanvasEmpty: action.payload.value,
      };
    }
    case "privacyAgreed": {
      return {
        ...state,
        isAgreed: action.payload.value,
      };
    }
    case "validSignature": {
      return {
        ...state,
        isValid: action.payload.value,
      };
    }
    default:
      return state;
  }
};
const Signature = ({ allFollowupData, uuid, cfirstName, removeFromPending }) => {
  const [state, dispatch] = useReducer(signatureReducer, initialState);
  const { saveFollowupUserSignature, isLoading } = useFollowupUserSignature();
  const [isChecked, setIsChecked] = useState(true);
  const signPadRef = useRef();
  const [signatureWrapWidth, setSignatureWrapWidth] = useState(0);
  const [signatureWrapHeight, setSignatureWrapHeight] = useState(0);
  const { updateMilestone } = useUpdateMilestone();
  const flp_source = allFollowupData.atp_sub6;
  const signatureWrapRef = useRef();
  const { signatureConfirmVaqV2Flp } = useClaimReg();

  const handleClick = async () => {
    try {
      const signPad = signPadRef.current;
      if (signPad.isEmpty()) {
        return dispatch({ type: "signPadChange", payload: { value: true } });
      }
      if (!isChecked) {
        return dispatch({ type: "privacyAgreed", payload: { value: true } });
      }
      const rpdata = signPad.toData();
      var signLength = 0;
      for (var i = 0; i < rpdata.length; i++) {
        signLength += Object.keys(rpdata[i]).length;
      }
      if (signLength > 13) {
        signatureConfirmVaqV2Flp(
          uuid,
          "followup-vaq",
          "signature_retake",
          allFollowupData.atp_sub6
          );
        const response = await saveFollowupUserSignature(
          signPad.getCanvas().toDataURL("image/png"),
          allFollowupData,
          'followup_user_signature'
        )
        if (response.data.status === "Success") {
          updateMilestone(uuid, "signature", flp_source);
          removeFromPending("signature");
        }
      } else {
        return dispatch({ type: "validSignature", payload: { value: true } });
      }
    } catch (e) {
      console.warn(e);
    }
  };
  useEffect(() => {
    if (isChecked) {
      dispatch({ type: "privacyAgreed", payload: { value: false } });
    }
  }, [isChecked]);

  useEffect(() => {
    function handleResize() {
      setSignatureWrapWidth(signatureWrapRef.current.clientWidth);
      setSignatureWrapHeight(signatureWrapRef.current.clientHeight);
    }
    setSignatureWrapWidth(signatureWrapRef.current.clientWidth);
    setSignatureWrapHeight(signatureWrapRef.current.clientHeight);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const clearSign = () => {
    if (signPadRef.current) signPadRef.current.clear();
  };
  return (
    <>
      <div className="signinpopup" >
        <div
          className={`modal blackover fade show`}
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content signinpopup">
              <div className="modal-body">
                <div className="row align-items-center text-center" >
                  <h2>IMPORTANT</h2>
                  <p>
                    <b>
                      PLEASE SIGN IN THE BOX BELOW TO START YOUR CLAIM
                    </b>
                  </p>
                  <div className="popup-sign-sec">
                    <div className="signbox" style={{ minHeight: '225px' }} ref={signatureWrapRef}>
                      <div
                        id="canvas"
                      >
                        <div>
                          <SignatureCanvas
                            canvasProps={{
                              id: "myID",
                              width: signatureWrapWidth,
                              height: signatureWrapHeight,
                            }}
                            ref={signPadRef}
                            onEnd={() => {
                              if (!signPadRef.current.isEmpty()) {
                                dispatch({
                                  type: "signPadChange",
                                  payload: { value: false },
                                });
                                dispatch({
                                  type: "validSignature",
                                  payload: { value: false },
                                });
                              }
                            }}
                          />
                        </div>
                        {state.isAgreed && (
                              <div className="sign_msg col-12 text-center">
                                Please tick to show your authorisation for us to
                                proceed
                              </div>
                            )}
                        {state.isCanvasEmpty && (
                          <div
                            className="sign_msg col-12 text-center sign_msg mb-3"
                            id="signatures_required"
                          >
                            Signature is required!
                          </div>
                        )}
                        {state.isValid && (
                          <div
                            className="sign_msg col-12 text-center sign_msg"
                            id="signatures_valid"
                          >
                            Draw valid signature!
                          </div>
                        )}
                        <div className="input-group mb-3 justify-content-lg-center justify-content-between">
                          <div className="mb-3 mt-3 col-5 p-0">
                            <input
                              type="button"
                              name="clearbutton"
                              id="clearbutton"
                              className="btn btn-secondary"
                              defaultValue="Clear"
                              onClick={clearSign}
                            />
                          </div>
                          <div className="mb-3 mt-3 col-7 p-0">
                            <input
                              type="button"
                              name="signbutton"
                              id="signbutton"
                              className="signbutton"
                              defaultValue="Review & Sign >"
                              onClick={handleClick}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-check my-3 d-flex">
                        <CheckBox2
                          dataId="acceptterms_flp"
                          anchorText=""
                          anchorStyle=""
                          href=""
                          onChange={(e) => setIsChecked(!isChecked)}
                          className="form-check-input"
                          labelClassName="form-check-label sign-custom-control-label"
                          value={isChecked}
                          checked={isChecked}
                          CheckBoxText="By clicking submit you agree to us appending your electronic signature to the claim documents and confirming that
                          you accept the updated terms of this contract for TMS Legal to represent you in your unaffordable/irresponsible lending claim."
                        />
                      </div>
                      <div>
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <ul>
                        <li>
                          <a href={LOA} target="_blank">
                            <i className="bi bi-arrow-up-right-square" /> Letter
                            of Authority
                          </a>
                        </li>
                        <li>
                          <a href={TOB} target="_blank">
                            <i className="bi bi-arrow-up-right-square" /> Terms of Business Agreement
                          </a>
                        </li>
                        <li>
                          <a href={VQ} target="_blank">
                            <i className="bi bi-arrow-up-right-square" />{" "}
                            Questionnaire
                          </a>
                        </li>
                        <li>
                          <a href={DECL} target="_blank">
                            <i className="bi bi-arrow-up-right-square" />{" "}
                            Declaration Form
                          </a>
                        </li>
                        <li>
                          <a href={DSAR} target="_blank">
                            <i className="bi bi-arrow-up-right-square" />{" "}
                            DSAR Letter of Authority
                          </a>
                        </li>
                      </ul>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Signature;
